import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="pyrokineticist"></a><h3>PYROKINETICIST</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80458.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d8.
    <a id="pyrokineticist-requirements"></a><h6>Requirements</h6>
To qualify to become a pyrokineticist, a character must fulfill all the
following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Any chaotic.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
8 ranks, <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a> (alchemy) 1 rank, <a href="psionicSkills.html#knowledge-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge (psionics)</a> 2 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psionics:</span> Must have a
power point reserve of at least 1 power point.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Must have set
fire to a structure of any size just to watch it burn.</p>
    <h6>Class Skills</h6>
The pyrokineticist&#8217;s class skills (and the key ability for each skill)
are <a href="skillsAll.html#climb" style={{
      "color": "rgb(87, 158, 182)"
    }}>Climb</a>
(Str), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(any) (Int), <a href="skillsAll.html#intimidate" style={{
      "color": "rgb(87, 158, 182)"
    }}>Intimidate</a>
(Cha), <a href="skillsAll.html#jump" style={{
      "color": "rgb(87, 158, 182)"
    }}>Jump</a>
(Str), and <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a> (Int). 
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier.</p>
    <a id="table-the-pyrokineticist"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Pyrokineticist </span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="1" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="1" style={{
            "width": "40%"
          }}>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+2</td>
          <td>+2</td>
          <td>+0</td>
          <td><span style={{
              "fontStyle": "italic"
            }}>Fire lash</span>
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>+0</td>
          <td>Fire adaptation, <span style={{
              "fontStyle": "italic"
            }}>hand
afire</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td><span style={{
              "fontStyle": "italic"
            }}>Bolt of fire</span>
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td><span style={{
              "fontStyle": "italic"
            }}>Weapon afire</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td><span style={{
              "fontStyle": "italic"
            }}>Nimbus</span>
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>Firewalk
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>Fear no fire
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6</td>
          <td>+6</td>
          <td>+6</td>
          <td>+2</td>
          <td><span style={{
              "fontStyle": "italic"
            }}>Greater weapon afire</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6</td>
          <td>+6</td>
          <td>+6</td>
          <td>+3</td>
          <td><span style={{
              "fontStyle": "italic"
            }}>Heat death</span>
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+7</td>
          <td className="last-row">+7</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row"><span style={{
              "fontStyle": "italic"
            }}>Conflagration</span>
          </td>
        </tr>
      </tbody>
    </table>
    <h6>Class Features</h6>
All the following are class features of the pyrokineticist prestige
class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Pyrokineticists gain no proficiency with any weapon or armor.</p>
    <a id="pyrokineticist-fire-lash"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Fire Lash
(Ps):</span> A pyrokineticist gains the ability to fashion a
15-foot-long whip of fire from unstable ectoplasm as a move-equivalent
action. She takes no damage from a fire lash she creates, and if she
releases her hold, it immediately dissipates. The lash deals 1d8 points
of fire damage to a target within 15 feet on a successful ranged touch
attack. A pyro can take <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Focus</a> and <a href="featsAll.html#weapon-specialization" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Specialization</a> (if she
otherwise meets the prerequisites) in conjunction with the fire lash,
as well as any feats that apply to the use of a standard whip. The whip
remains in existence as long as the pyrokineticist holds it.</p>
    <a id="pyrokineticist-fire-adaptation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Fire Adaptation (Ex):</span>
At 2nd level, a pyrokineticist becomes resistant to fire, gaining a +4
bonus on all saving throws against fire and heat spells and effects. In
addition, she gains <a href="monsterTypes.html#resistance-to-energy" style={{
        "color": "rgb(87, 158, 182)"
      }}>resistance to fire</a> 10.</p>
    <a id="pyrokineticist-hand-afire"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Hand
Afire (Ps):</span> A pyrokineticist of 2nd level or higher can activate
this ability as a move-equivalent action. Flames engulf one of the
pyrokineticist&#8217;s hands (but do her no harm). Her unarmed attacks with
that hand are treated as armed. Such attacks deal an extra 2d6 points
of fire damage. </p>
    <a id="pyrokineticist-bolt-of-fire"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Bolt of
Fire (Ps):</span> Starting at 3rd level, as a standard action, a
pyrokineticist can launch a bolt of psionically manifested fire at any
target in line of sight within 60 feet. This effect is treated as a
ranged touch attack and deals 1d6 points of fire damage for each class
level the pyro has.</p>
    <a id="pyrokineticist-weapon-afire"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Weapon
Afire (Ps):</span> At 4th level and higher, a pyrokineticist can
activate this ability as a move-equivalent action. Flames that harm
neither her nor the weapon engulf one weapon she holds (which can be a
projectile such as a stone, bullet, arrow, or bolt). The weapon deals
an extra 2d6 points of fire damage on a successful hit. The weapon
retains this effect for as long as the pyrokineticist wields it.</p>
    <a id="pyrokineticist-nimbus"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Nimbus
(Ps):</span> Beginning at 5th level, a pyrokineticist can activate this
ability as a move-equivalent action. Flames that harm neither the
pyrokineticist nor her equipment engulf her entire body. While she is
aflame, the character&#8217;s Charisma score increases by 4, she can make a
melee touch attack for 2d6 points of fire damage, and she gains damage
reduction 5/magic. If she is struck in melee, the attacker takes 2d6
points of fire damage. This ability lasts for up to 1 minute per
pyrokineticist level and is usable once per day.</p>
    <a id="pyrokineticist-firewalk"></a><p><span style={{
        "fontWeight": "bold"
      }}>Firewalk (Su):</span>
Beginning at 6th level, as a free action a pyrokineticist can expend
her psionic focus (see the <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> skill description)
to
literally walk on air. She moves at her normal speed in all directions,
including vertically, but cannot move more than double her speed in a
round. A firewalking pyro leaves footprints of flame in the air that
disperse in 2 rounds, but her tread does not deal damage. She must pay
1 power point per round spent traveling in this fashion.</p>
    <a id="pyrokineticist-fear-no-fire"></a><p><span style={{
        "fontWeight": "bold"
      }}>Fear No Fire (Ex):</span> At
7th level, a pyrokineticist becomes highly resistant to fire, gaining a
+8 bonus on all saving throws against fire and heat spells and effects
and also gaining <a href="monsterTypes.html#resistance-to-energy" style={{
        "color": "rgb(87, 158, 182)"
      }}>resistance to fire</a> 20.</p>
    <a id="pyrokineticist-freater-weapon-afire"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Greater
Weapon Afire (Ps):</span> At 8th level, when a pyrokineticist activates
her hand afire ability or her weapon afire ability, her unarmed attack
or weapon deals an extra 4d6 points of fire damage instead of 2d6.
Touch attacks made while she uses the nimbus ability likewise deal 4d6
points of damage instead of 2d6.</p>
    <a id="pyrokineticist-heat-death"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Heat
Death (Ps):</span> A pyrokineticist who reaches 9th level can expend
her psionic focus and take a full attack action to raise the internal
temperature of one living creature within 30 feet to lethal levels. The
target must succeed on a Fortitude saving throw (DC 14 + pyro&#8217;s Cha
modifier) or die horrifically as its blood (or other internal fluid)
boils. Even on a successful save, the target takes 4d8 points of fire
damage from the heat.</p>
    <a id="pyrokineticist-conflagration"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Conflagration
(Ps):</span> At 10th level, a pyrokineticist gains the ability to
create a massive burst of raging flames around herself, burning
everything in the area. Once per day, as a standard action, she can use
this ability to deal 15d6 points of fire damage in a 30-foot-radius
burst emanating from herself. Any creature or object caught in the
burst can make a Reflex saving throw (DC 15 + pyro&#8217;s Cha modifier) for
half damage. Anyone failing the Reflex save against the conflagration
must also make a Fortitude saving throw (same DC) or die due to extreme
shock from the intense heat.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      